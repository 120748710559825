<template>
  <div>
    <div class="alert-wrapper" v-show="showCookieBanner">
      <div class="overlay"></div>
      <div class="alerts">
        <div class="header-alert" key="notf-header">
          <nuxt-img
            quality="90"
            format="webp"
            src="/icons/icons/shots-icon-white.svg"
            alt="small logo"
            placeholder
          />
          <div @click="denyCookies()">
            <Icon class="remove-icon" icon="iconamoon:sign-times" />
          </div>
        </div>
        <div class="cookie-banner">
          <p>
            {{
              $t(
                "Our website uses cookies, but don't worry, they're the digital kind!"
              )
            }}
          </p>
          <p>
            {{
              $t(
                "Just a springle of tech magic to make your browsing a happy moment."
              )
            }}
          </p>
          <div class="my-6">
            <p>
              {{ $t("Not feeling it? No need for your green light here.") }}
            </p>
            <p>
              {{
                $t("The standard settings are our go-to whenever you drop by.")
              }}
            </p>
          </div>
          <button class="btn" @click="acceptCookies">{{ $t("Allow") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import { useConfigStore } from "~/stores/config";
import { computed, useContext } from "@nuxtjs/composition-api";

export default {
  components: {
    Icon,
  },
  setup() {
    const configStore = useConfigStore();
    const { app } = useContext();
    const $cookies = app.$cookies;

    const showCookieBanner = computed(() => configStore.showCookieBanner);

    const acceptCookies = () => {
      $cookies.set("cookieConsent", true, { maxAge: 31536000 });
      configStore.showCookieBanner = false;
    };

    const denyCookies = () => {
      $cookies.set("cookieConsent", false, { maxAge: 31536000 });
      configStore.showCookieBanner = false;
    };

    return {
      showCookieBanner,
      acceptCookies,
      denyCookies,
    };
  },
};
</script>

<style scoped lang="scss">
.cookie-banner {
  color: #000000;
  @apply font-fgb;
  padding: 40px;
  padding-bottom: 60px;
  text-align: center;
  @media only screen and (max-width: 480px) {
    padding: 40px 20px;
  }
}
.alert-wrapper {
  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    background: #909090 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    overscroll-behavior: contain;
  }
}
.alerts {
  @apply bg-white relative opacity-100;
  border-radius: 1rem;
  background-image: url("@/assets/images/Intersection.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0px bottom;
  position: fixed;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  z-index: 40;
  @media only screen and (max-width: 768px) {
    max-width: 500px;
    width: 100%;
  }
  @media only screen and (max-width: 480px) {
    max-width: 350px;
    width: 100%;
  }
  .header-alert {
    @apply flex justify-center py-2;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    background: rgb(227, 6, 19);
    background: linear-gradient(
      90deg,
      rgba(227, 6, 19, 1) 0%,
      rgba(167, 3, 3, 1) 68%
    );
    img {
      width: 25px;
      margin: 5px;
      height: auto;
      transform: rotate(-10deg);
    }
    .remove-icon {
      @apply absolute text-white text-2xl cursor-pointer;
      top: 5px;
      right: 10px;
      transition: all 100ms ease-in;
      font-size: 50px;
      &:active {
        transform: scale(0.9);
      }
    }
  }
  .alert-content {
    @apply flex justify-center items-center text-center py-8 px-10 font-bold font-mont;
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s;
  @include for-desktop {
    transition: opacity 0.25s linear;
  }
}

.slide-fade-enter {
  transform: translateY(40px);
  @include for-desktop {
    opacity: 0;
  }
}

.slide-fade-leave-to {
  transform: translateY(80px);
  @include for-desktop {
    opacity: 0;
  }
}
.btn {
  padding: 10px 20px;
  min-width: auto;
}
</style>
